import { format } from 'date-fns';
import isDate from 'date-fns/isDate';
import { OrderHistoryTrackingLink } from '../OrderHistoryTrackingLink.jsx';
import { OrderHistoryTracking } from '../OrderHistoryTracking';

export const COLUMN_HEADERS = {
  PATIENT_NAME: 'Patient name',
  ORDER_NUMBER: 'Order number',
  TEST_NAME: 'Test name',
  TEST_LOCATION: 'Test location',
  ORDER_DATE: 'Order date',
  PAYMENT_LINK: 'Payment link',
  TRACKING_LINK: 'Tracking',
  STATUS: 'Status',
};

const formatDate = (createdAt) => {
  if (!createdAt) {
    return null;
  }

  const date = new Date(createdAt);
  if (isDate(date)) {
    return format(date.getTime(), 'dd/MM/yy');
  }
  return null;
};

export const formatOrderHistoryRow =
  ({ onClick, selectedOrder }) =>
  ({ firstName, lastName, orderNumber, createdAt, paymentLabel, testTitle, orderStatus }) => {
    const isRowActive = orderNumber === selectedOrder;
    return {
      [COLUMN_HEADERS.PATIENT_NAME]: [firstName, lastName].join(' '),
      [COLUMN_HEADERS.ORDER_NUMBER]: orderNumber,
      [COLUMN_HEADERS.TEST_NAME]: testTitle,
      [COLUMN_HEADERS.TEST_LOCATION]: 'Home Kit',
      [COLUMN_HEADERS.ORDER_DATE]: formatDate(createdAt),
      [COLUMN_HEADERS.PAYMENT_LINK]: paymentLabel,
      [COLUMN_HEADERS.TRACKING_LINK]: (
        <>
          <OrderHistoryTrackingLink isActive={isRowActive} orderNumber={orderNumber} onClick={onClick} />
          {isRowActive && <OrderHistoryTracking orderNumber={orderNumber} />}
        </>
      ),
      [COLUMN_HEADERS.STATUS]: orderStatus,
      ...(isRowActive && { shouldDisplayDivider: false }),
      ...(isRowActive && { classNames: 'relative align-baseline h-40' }),
    };
  };
