import { Field, Form, Formik } from 'formik';
import { Button, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import * as CustomMuiStyle from '../covid-results/shared.style';
import { Header } from '../../../components';
import { testStatusesMeta, testStatusesOrder } from './test-results.config';
import { DropDownDeprecated } from '../covid-results/covid-19.dropdown';
import { Filter } from '../../../icons';
import { TestResultsExport } from './TestResultsExport';

export const TestResultsHeader = ({ filterState, handleFiltersSubmit, selectedTabIdx, setFilterByStatusChange, testsData }) => (
  <CustomMuiStyle.StyledGridHeaderWrapper>
    <CustomMuiStyle.StyledGridHeader>
      <Header element="h1">Test Results</Header>
    </CustomMuiStyle.StyledGridHeader>
    <CustomMuiStyle.StyledGridSubHeader>
      <CustomMuiStyle.StyledGridSWrapper>
        <CustomMuiStyle.StyledTabs
          value={selectedTabIdx}
          onChange={setFilterByStatusChange}
          aria-label="Filter test results by status"
          TabIndicatorProps={{
            style: {
              background: '#1B1D26',
              borderRadius: '2px',
            },
          }}
        >
          {testStatusesOrder.map((testStatusId) => {
            const meta = testStatusesMeta[testStatusId];
            return (
              <CustomMuiStyle.StyledGridHeaderSubTitle
                label={`${meta.label} (${testsData.count[testStatusId] || 0})`}
                index={meta.label}
                key={meta.label + 100}
              />
            );
          })}
        </CustomMuiStyle.StyledTabs>
      </CustomMuiStyle.StyledGridSWrapper>

      <CustomMuiStyle.StyledGridSWrapper>
        <TestResultsExport status={filterState.status} />
        <DropDownDeprecated variant="transparent" label="FILTER" icon={Filter}>
          <div>
            <CustomMuiStyle.StyledDropDownTitle>Filter table data</CustomMuiStyle.StyledDropDownTitle>
            <Formik
              initialValues={{
                productTestType: filterState.productTestType || '',
                orderNumber: filterState.orderNumber || '',
                username: filterState.username || '',
                startTime: (filterState.startTime && new Date(filterState.startTime * 1000).toISOString().slice(0, 10)) || '',
                endTime: (filterState.endTime && new Date(filterState.endTime * 1000).toISOString().slice(0, 10)) || '',
              }}
              enableReinitialize
              onSubmit={(newFilters, { setSubmitting }) => {
                handleFiltersSubmit(newFilters);
                setSubmitting(false);
              }}
            >
              {({
                values,
                // errors,
                // touched,
                handleChange,
                handleBlur,
                // handleSubmit,
                // isSubmitting,
                isValid,
                // resetForm,
                /* and other goodies */
              }) => (
                <Form name="filter" id="filter">
                  <CustomMuiStyle.StyledBox>
                    <CustomMuiStyle.StyledGroupTwo>
                      <Field
                        focused
                        component={TextField}
                        name="startTime"
                        id="startTime"
                        type="date"
                        label="Start Date"
                        helperText="Please enter your start date"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.startTime}
                        format="dd/MM/yyyy"
                      />
                      <Field
                        focused
                        component={TextField}
                        name="endTime"
                        id="endTime"
                        type="date"
                        label="End Date"
                        helperText="Please enter your end date"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.endTime}
                        format="dd/MM/yyyy"
                      />
                    </CustomMuiStyle.StyledGroupTwo>
                  </CustomMuiStyle.StyledBox>
                  <CustomMuiStyle.StyledBox>
                    <Field
                      component={TextField}
                      fullWidth
                      name="orderNumber"
                      id="orderNumber"
                      type="text"
                      label="Order number"
                      helperText="Please enter your order number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.orderNumber}
                    />
                  </CustomMuiStyle.StyledBox>
                  <CustomMuiStyle.StyledBox>
                    <Field
                      component={TextField}
                      fullWidth
                      name="username"
                      id="username"
                      type="text"
                      label="First Name/Surname"
                      helperText="Please enter your first name/surname"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.username}
                    />
                  </CustomMuiStyle.StyledBox>
                  <CustomMuiStyle.StyledBox>
                    <CustomMuiStyle.StyledGroupTwo>
                      <Button disabled={!isValid} type="submit">
                        Submit
                      </Button>
                      <Button
                        color="primary"
                        variant="outlined"
                        type="reset"
                        onClick={() => {
                          handleFiltersSubmit({});
                        }}
                      >
                        Reset
                      </Button>
                    </CustomMuiStyle.StyledGroupTwo>
                  </CustomMuiStyle.StyledBox>
                </Form>
              )}
            </Formik>
          </div>
        </DropDownDeprecated>
      </CustomMuiStyle.StyledGridSWrapper>
    </CustomMuiStyle.StyledGridSubHeader>
  </CustomMuiStyle.StyledGridHeaderWrapper>
);

TestResultsHeader.propTypes = {
  filterState: PropTypes.object.isRequired,
  handleFiltersSubmit: PropTypes.func.isRequired,
  selectedTabIdx: PropTypes.number.isRequired,
  setFilterByStatusChange: PropTypes.func.isRequired,
  testsData: PropTypes.object.isRequired,
};
